import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import rmc from "../../Assets/Projects/rmc.png";
import hh from "../../Assets/Projects/hh.png";
import fs from "../../Assets/Projects/fs.png";
import ps from "../../Assets/Projects/ps.png";
import cci from "../../Assets/Projects/cci.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ps}
              isBlog={false}
              title="Power Slap"
              description="Power Slap is a turn-based fighting game that brings the excitement of a virtual slap contest to your pocket. Test your timing, precision, and strategy to rise through the ranks and become the ultimate Power Slap champion!"
              appStoreLink="https://apps.apple.com/tr/app/power-slap/id6449244841"
              gPlayStoreLink="https://play.google.com/store/apps/details?id=com.uncosoft.powerslap"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hh}
              isBlog={false}
              title="High Heels!"
              description="Get ready for the ultimate high heels challenge! Strut, jump, and balance your way through obstacles. The taller your heels, the easier it gets. Can you reach the podium?"
              appStoreLink="https://apps.apple.com/us/app/high-heels/id1545306176"
              gPlayStoreLink="https://play.google.com/store/apps/details?id=com.uncosoft.highheels"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fs}
              isBlog={false}
              title="Pink Stars"
              description="Welcome to the dazzling world of Pink Stars — a place where high heels and high stakes meet on the fashion runway! Stack your heels, dodge obstacles, and serve looks as you journey to become the ultimate style icon."
              appStoreLink="https://apps.apple.com/cz/app/pink-stars/id6465895459"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cci}
              isBlog={false}
              title="Car Care Inc."
              description="Become a car care tycoon in Car Care Inc! Start with a small Car Wash and grow your business into a car service empire. Expand, hire staff, and automate to maximize profits. Upgrade equipment, offer more services, and aim to be the world's top Car Care Millionaire!"
              appStoreLink="https://apps.apple.com/us/app/car-care-inc/id6448446762"
              gPlayStoreLink="https://play.google.com/store/apps/details?id=com.uncosoft.carventure"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rmc}
              isBlog={false}
              title="Repair My Car"
              description="Repair broken cars, clean oils, renew pistons, charge the battery, and install a new one if necessary to get ready for the race!"
              appStoreLink="https://apps.apple.com/us/app/repair-my-car/id1511913020"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
