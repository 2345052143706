import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FaApple } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";

// import { AppStore } from "react-icons/ai";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body className="d-flex flex-column">
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <div className="mt-auto">
          {props.appStoreLink && (
            <Button variant="primary" href={props.appStoreLink} target="_blank" className="me-2 mb-2">
              <FaApple /> &nbsp;
              {props.isBlog ? "Blog" : "App Store"}
            </Button>
          )}
          {props.gPlayStoreLink && (
            <Button variant="primary" href={props.gPlayStoreLink} target="_blank" className="me-2 mb-2">
              <FaGooglePlay /> &nbsp;
              {props.isBlog ? "Blog" : "Play Store"}
            </Button>
          )}
          {!props.isBlog && props.demoLink && (
            <Button
              variant="primary"
              href={props.demoLink}
              target="_blank"
              className="mb-2"
            >
              <CgWebsite /> &nbsp;
              {"Demo"}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
